import React from "react";
import Signup from "./authentication/Signup";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Profile from "./authentication/Profile";
import Login from "./authentication/Login";
import PrivateRoute from "./authentication/PrivateRoute";
import AdminPrivateRoute from "./authentication/AdminPrivateRoute";
import ForgotPassword from "./authentication/ForgotPassword";
import UpdateProfile from "./authentication/UpdateProfile";
import Dashboard from "./google-drive/Dashboard";
import AdminDashboard from "./google-drive/AdminDashboard";

import Admin from "./google-drive/Admin";
import "./all.css";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          {/* Drive */}
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/folder/:folderId" component={Dashboard} />

          {/* Admin Dashboard file */}
          <AdminPrivateRoute
            exact
            path="/adminfile/:id/:email"
            component={AdminDashboard}
          />
          <AdminPrivateRoute
            exact
            path="/adminfile/folder/:folderId/:email"
            component={AdminDashboard}
          />
          {/* Admin Dashboard */}
          <AdminPrivateRoute exact path="/admin" component={Admin} />

          {/* Add new user */}
          <AdminPrivateRoute exact path="/signup" component={Signup} />

          {/* Profile */}
          <PrivateRoute path="/user" component={Profile} />
          <PrivateRoute path="/update-profile" component={UpdateProfile} />

          {/* Auth */}

          <Route path="/login" component={Login} />
          <AdminPrivateRoute
            path="/forgot-password"
            component={ForgotPassword}
          />
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
