import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
const email = process.env.REACT_APP_FIREBASE_EMAIL;

export default function NavbarComponent() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }
  return (
    <Navbar bg="light" expand="sm">
      <Navbar.Brand as={Link} to="/">
        <img src="/images/jainimbrand.png" style={{ width: "170px" }}></img>{" "}
        <span style={{ position: "relative", bottom: "3px" }}>Drive</span>
      </Navbar.Brand>
      <h6 style={{ marginTop: "30px", marginLeft: "auto", marginTop: "0" }}>
        To change password please contact{" "}
        <span style={{ color: "#007bff" }}>help@jainim.ca</span>
      </h6>
      <Nav style={{ marginLeft: "auto" }}>
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="outlined"
            endIcon={<FontAwesomeIcon icon={faCaretDown} className="mr-2" />}
          >
            Menu
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>{currentUser.email}</MenuItem>
            {currentUser && currentUser?.email == email && (
              <div>
                <MenuItem onClick={handleClose}>
                  <Button>
                    <Link style={{ textDecoration: "none" }} to="/admin">
                      File Manager
                    </Link>
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Button>
                    <Link style={{ textDecoration: "none" }} to="/signup">
                      Add new user
                    </Link>
                  </Button>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                  <Button>
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/forgot-password"
                    >
                      Change user password
                    </Link>
                  </Button>
                </MenuItem>
              </div>
            )}
            <MenuItem onClick={handleClose}>
              <Button onClick={handleLogout}>Logout</Button>
            </MenuItem>
          </Menu>
        </div>
      </Nav>
    </Navbar>
  );
}
