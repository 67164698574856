import fileDownload from "js-file-download";
import axios from "axios";
export const handleDownload = (url, filename) => {
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
};

export const getPathStorageFromUrl = (url) => {
  const baseUrl =
    "https://firebasestorage.googleapis.com/v0/b/auth-development-69940.appspot.com/o/";

  let imagePath = url.replace(baseUrl, "");

  const indexOfEndPath = imagePath.indexOf("?");

  imagePath = imagePath.substring(0, indexOfEndPath);

  imagePath = imagePath.replace("%2F", "/");

  return imagePath;
};
