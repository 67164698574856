import React from "react";
import { Container } from "react-bootstrap";
import { useFolder } from "../../hooks/useFolder";
import AddFolderButton from "./AddFolderButton";
import AddFileButton from "./AddFileButton";
import AdminFolder from "./AdminFolder";
import AdminFile from "./AdminFile";
import Navbar from "./Navbar";
import AdminFolderBreadcrumbs from "./AdminFolderBreadcrumbs";
import { useParams, useLocation } from "react-router-dom";
import { database, storage } from "../../firebase.js";
import DeleteDialog from "./DeleteDialog";
import { getPathStorageFromUrl } from "../../utils/download";
const storageRef = storage.ref();
export default function Dashboard() {
  const [open, setOpen] = React.useState(false);
  const [store, setStore] = React.useState({ id: "", file: "", url: "" });
  const { folderId } = useParams();
  const { state = {} } = useLocation();
  const { id, email } = useParams();
  const { folder, childFolders, childFiles } = useFolder(
    folderId,
    state.folder,
    id
  );
  function deleteFilePopUp(id, file, url) {
    setStore({ id, file, url });
    setOpen(true);
  }

  function deleteFileconfirm() {
    if (store.id != "") {
      database[store.file].doc(store.id).delete();

      // [START storage_delete_file]
      // Create a reference to the file to delete
      const imagePath = getPathStorageFromUrl(store.url);
      var desertRef = storageRef.child(imagePath);

      // // Delete the file
      // desertRef
      //   .delete()
      //   .then(() => {
      //     // File deleted successfully
      //     console.log("deleted success");
      //   })
      //   .catch((error) => {
      //     // Uh-oh, an error occurred!
      //   });
      // // [END storage_delete_file]
    } else {
      alert("id is not correct");
    }
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Navbar />
      <Container fluid>
        {/* Delete dialog */}
        <DeleteDialog
          open={open}
          handleClose={handleClose}
          deleteFileconfirm={deleteFileconfirm}
        />
        <div className="d-flex align-items-center">
          <AdminFolderBreadcrumbs email={email} currentFolder={folder} />
          <AddFileButton email={email} userId={id} currentFolder={folder} />

        </div>

        {childFolders.length > 0 && (
          <div className="d-flex flex-wrap">
            {childFolders.map((childFolder) => (
              <div
                key={childFolder.id}
                style={{ maxWidth: "400px", width: "400px" }}
                className="p-2"
              >
                <AdminFolder
                  deleteFilePopUp={deleteFilePopUp}
                  folder={childFolder}
                  email={email}
                />
              </div>
            ))}
          </div>
        )}
        {childFolders.length > 0 && childFiles.length > 0 && <hr />}
        {childFiles.length > 0 && (
          <div className="d-flex flex-wrap">
            {childFiles.map((childFile) => (
              <div
                key={childFile.id}
                style={{ maxWidth: "400px", width: "400px" }}
                className="p-2"
              >
                <AdminFile deleteFilePopUp={deleteFilePopUp} file={childFile} />
                <br></br>
              </div>
            ))}
          </div>
        )}
      </Container>
    </>
  );
}
