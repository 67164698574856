import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "./DeleteIcon";
const DeleteDialog = ({ open, handleClose, deleteFileconfirm }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <center>
          <DeleteIcon />
        </center>
        <center>
          {" "}
          <DialogTitle id="alert-dialog-title">{"Are you Sure?"}</DialogTitle>
        </center>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This Action will delete this file from the system
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteFileconfirm} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteDialog;
