import React from "react";
import { Link } from "react-router-dom";
import { Button as LinkButton } from "react-bootstrap";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faTrashAlt,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";
import { handleDownload } from "../../utils/download";
export default function Folder({ folder, deleteFilePopUp, email }) {
  return (
    <div className="d-flex justify-between">
      <LinkButton
        to={{
          pathname: `/adminfile/folder/${folder.id}/${email}`,
          state: { folder: folder },
        }}
        variant="outline-dark"
        className="text-truncate"
        as={Link}
      >
        <FontAwesomeIcon icon={faFolder} className="mr-2" />
        {folder.name}
      </LinkButton>
      <div className="ml-5">
        <Button onClick={() => deleteFilePopUp(folder.id, "folders")}>
          <FontAwesomeIcon
            size="lg"
            icon={faTrashAlt}
            className="mr-2 remove__button"
          />
        </Button>
      </div>
    </div>
  );
}
