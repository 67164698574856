import {
  faFile,
  faTrashAlt,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "@mui/material/Button";
import { handleDownload } from "../../utils/download";

export default function File({ file, deleteFilePopUp }) {
  return (
    <div className="d-flex justify-between">
      <a
        href={file.url}
        target="_blank"
        className="btn btn-outline-dark text-truncate"
      >
        <FontAwesomeIcon icon={faFile} className="mr-2" />
        {file.name}
      </a>

      <div className="ml-5">
        <Button
          onClick={() => handleDownload(file.url, file.name)}
          title={`Download ${file.name}`}
        >
          <FontAwesomeIcon
            size="lg"
            icon={faCloudDownloadAlt}
            className="mr-2 remove__button"
          />
        </Button>
        <Button
          onClick={() => deleteFilePopUp(file.id, "files", file.url)}
          style={{
            border: "none",
            color: "white",
            outline: "none",
            backgroundColor: "white",
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} className="mr-2 remove__button" />
        </Button>
      </div>
    </div>
  );
}
