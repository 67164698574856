import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
const email = process.env.REACT_APP_FIREBASE_EMAIL;
export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser && currentUser.email == email ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    ></Route>
  );
}
