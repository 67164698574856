import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import Navbar from "./Navbar";
import { database } from "../../firebase.js";
import { Link } from "react-router-dom";
export default function Dashboard() {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    return database.users.onSnapshot((snapshot) => {
      let resusers = snapshot.docs.map(database.formatDoc);
      setUsers(resusers);
    });
  }, [database]);
  const handleFile = () => {};
  return (
    <>
      <Navbar />
      <Container>
        <h3 className="text-center my-5">Manage users files</h3>

        <div
          style={{ backgroundColor: "#f8f9fa" }}
          className="d-flex flex-wrap align-items-center p-5 my-5"
        >
          {users.map((user) => (
            <div key={user.id} className="m-5">
              <Button variant="outlined" onClick={handleFile}>
                <Link to={`/adminfile/${user.userId}/${user.email}`}>
                  {user.email}
                </Link>
              </Button>
            </div>
          ))}
        </div>
      </Container>
    </>
  );
}
